import React from 'react';

import MobileLogo from 'images/mobilelogo.png';
import 'styles/layout/footer.scss';
import { Link } from 'gatsby';

const Footer = () => {
  return (
    <>
      <footer>
        <Link to='/' className='footer-logo'>
          <img src={MobileLogo} alt='Mobile Air Service Logo' />
        </Link>
        <nav>
          <Link to='/' className='footer-link'>
            Home
          </Link>
          <Link to='/about' className='footer-link'>
            About Us
          </Link>
          <Link to='/services' className='footer-link'>
            Services
          </Link>
          <Link to='/contact' className='footer-link'>
            Contact
          </Link>
        </nav>
        <div className='footer-socials'>
          <a
            href='https://www.facebook.com/Mobile-Air-Service-Inc-1853161298242487'
            target='_blank'
            rel='noreferrer'
            noopener='true'
          >
            <i className='uil uil-facebook'></i>
          </a>
          <a
            href='https://www.instagram.com/mobileairservice/'
            target='_blank'
            rel='noreferrer'
          >
            <i className='uil uil-instagram'></i>
          </a>
          <a href='tel:204-803-5473'>
            <i className='uil uil-phone'></i>
          </a>
          <a href='mailto:josh.wilson@mobileairservice.com'>
            <i className='uil uil-envelope'></i>
          </a>
        </div>
      </footer>
      <p className='arr'>
        All rights reserved © Mobile Air Service {new Date().getFullYear()}
      </p>
    </>
  );
};

export default Footer;
