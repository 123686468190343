import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';

import MobileLogo from 'images/mobilelogo.png';
import 'styles/layout/navigation.scss';

const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [prevScrollPosition, setPrevScrollPosition] = useState(0);

  useEffect(() => {
    const updatePosition = () => {
      if (window.pageYOffset < 90) return;
      setScrollPosition((prev) => {
        setPrevScrollPosition(prev);
        return window.pageYOffset;
      });
    };
    window.addEventListener('scroll', updatePosition);
    updatePosition();
    return () => window.removeEventListener('scroll', updatePosition);
  }, []);

  return { scrollPosition, prevScrollPosition };
};

const Navigation = () => {
  const [openedNav, setOpenedNav] = useState(false);
  const { scrollPosition, prevScrollPosition } = useScrollPosition();
  const btnClickHandler = () => {
    if (openedNav) return setOpenedNav(false);
    return setOpenedNav(true);
  };

  const linkClickHandler = () => {
    return setOpenedNav(false);
  };
  return (
    <header
      style={prevScrollPosition < scrollPosition ? { top: -90 } : { top: 0 }}
    >
      <div className='nav-container'>
        <div className='nav-links'>
          <Link to='/contact' className='nav-link'>
            <i className='uil uil-phone'></i>
            Contact
          </Link>
          <Link to='/blog' className='nav-link'>
            <i className='uil uil-file-edit-alt'></i>
            Blog
          </Link>
        </div>
        <Link to='/' className='nav-logo'>
          <img src={MobileLogo} alt='Mobile Air Service Logo' />
        </Link>
        <div className='nav-links'>
          <Link to='/about' className='nav-link'>
            <i className='uil uil-info-circle'></i>
            About Us
          </Link>
          <Link to='/services' className='nav-link'>
            <i className='uil uil-wrench'></i>
            Services
          </Link>
        </div>
      </div>
      <div className={`nav-container-mobile ${openedNav ? 'open' : ''}`}>
        <Link to='/contact' className='nav-link-contact'>
          <i className='uil uil-phone icon-mobile-nav'></i>
        </Link>
        <Link to='/' className='nav-logo'>
          <img src={MobileLogo} alt='Mobile Air Service Logo' />
        </Link>
        <button className='btn-mobile-nav' onClick={btnClickHandler}>
          <i className='uil uil-bars icon-mobile-nav' name='menu-outline'></i>
          <i
            className='uil uil-multiply icon-mobile-nav'
            name='close-outline'
          ></i>
        </button>
        <div className='nav-links-mobile'>
          <Link to='/' className='nav-link-mobile' onClick={linkClickHandler}>
            Home
          </Link>
          <Link
            to='/services'
            className='nav-link-mobile'
            onClick={linkClickHandler}
          >
            Services
          </Link>
          <Link
            to='/about'
            className='nav-link-mobile'
            onClick={linkClickHandler}
          >
            About Us
          </Link>
          <Link
            to='/blog'
            className='nav-link-mobile'
            onClick={linkClickHandler}
          >
            Blog
          </Link>
          <Link
            to='/contact'
            className='nav-link-mobile'
            onClick={linkClickHandler}
          >
            Contact Us
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Navigation;
