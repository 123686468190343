import React from 'react';
import { Link } from 'gatsby';

import Layout from 'components/global/layout';
import Seo from 'components/global/seo';

import 'styles/pages/404.scss';

const NotFoundPage = () => {
  return (
    <Layout>
      <div className='notfound'>
        <div className='notfound-textbox'>
          <span className='tag'>Error 404</span>
          <h1>Page not found</h1>
          <p>Sorry! We are unable to find the page you are looking for</p>
          <Link to='/'>Back home</Link>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;

export const Head = () => <Seo title='404' />;
